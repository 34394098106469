/**
 * Import main SCSS entry point to utilize Vite's built-in capabilities
 * for handling styles. This will create a separate bundle for the css.
 */
import '../scss/main.scss';
import './modules/libs';

import { componentModules } from './modules/components';
import { defaultModules } from './modules/defaultModules';
import { helperModules } from './modules/helpers';
import { importDefaultModule, importModule } from './modules/importModule';

document.addEventListener('DOMContentLoaded', () => {
  [...defaultModules].forEach(importDefaultModule);
  [...componentModules, ...helperModules].forEach(importModule);
});
