import { DefaultImport } from './importModule';

const analytics: DefaultImport = {
  importFn: [
    async () => {
      const { userTracking, noEditorTracking, gaOptout, searchResults } =
        await import('@ts/helpers/analytics');

      userTracking();
      noEditorTracking();
      gaOptout();

      // Only run search analytics when search form is present on page
      if (document.querySelector<HTMLElement>('.js-search-form')) {
        searchResults();
      }
    },
  ],
};

const handleColorScheme: DefaultImport = {
  importFn: [
    async () => {
      const { handleColorScheme } = await import(
        '@ts/helpers/handleColorScheme'
      );
      handleColorScheme();
    },
  ],
};

const svgCorrectColor: DefaultImport = {
  importFn: [
    async () => {
      const { svgCorrectColor } = await import('@ts/helpers/icons');
      svgCorrectColor('bookmark');
    },
  ],
};

export const defaultModules = [analytics, handleColorScheme, svgCorrectColor];
