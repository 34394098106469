import { Import } from './importModule';

const alphabetEditorial: Import = {
  selector: ['.pts-alphabet-editorial'],
  importFn: [() => import('@components/Alphabet/AlphabetEditorial')],
};

const articleImages: Import = {
  selector: ['.pts-article-image'],
  importFn: [() => import('@components/ArticleImages/ArticleImages')],
};

const articleInfoBoxes: Import = {
  selector: ['.bo-article-text'],
  importFn: [() => import('@components/ArticleBoxes/InfoBox')],
};

export const bentoBox: Import = {
  selector: ['.pts-bento-box-card'],
  importFn: [() => import('@components/BentoBox/BentoBox')],
};

const comments: Import = {
  selector: ['.pts-comments'],
  importFn: [() => import('@components/Comments/CommentsWrapper')],
};

const footer: Import = {
  selector: ['.pts-footer'],
  importFn: [() => import('@components/Footer/Footer')],
};

const maxInputFeedback: Import = {
  selector: ['#form[action$="/@@edit"]', '#form[action*="++add++"]'],
  importFn: [
    async () => {
      const { MaxInputFeedback } = await import(
        '@ts/components/frontend-ui/MaxInputFeedback/MaxInputFeedback'
      );
      MaxInputFeedback();
    },
  ],
};

const newsletterSignUp: Import = {
  selector: ['.pts-newsletter-sign-up'],
  importFn: [() => import('@components/NewsletterSignUp/NewsletterSignUp')],
};

const newsletterUsp: Import = {
  selector: ['.pts-newsletter-usp'],
  importFn: [() => import('@components/NewsletterUsp/NewsletterUsp')],
};

const socialShareMenu: Import = {
  selector: ['.pts-socialmedia'],
  importFn: [() => import('@components/SocialShareMenu/SocialShareMenu')],
};

const tagPageListing: Import = {
  selector: ['.pts-tag-page-listing'],
  importFn: [() => import('@components/TagPageListing/TagPageListing')],
};

export const componentModules = [
  alphabetEditorial,
  articleImages,
  articleInfoBoxes,
  bentoBox,
  comments,
  footer,
  maxInputFeedback,
  newsletterSignUp,
  newsletterUsp,
  socialShareMenu,
  tagPageListing,
];
